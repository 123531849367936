import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../models/user';
import { UserRole } from '../models/roles';
import { NotificationService } from '../common/services/notification.service';
import { FacebookLoginProvider, SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { ExternalLoginModel } from '../models/external-login';
import { ExternalLoginFacebookModel } from '../models/external-fb-login';
import { LoginFacebook } from '../models/login-facebook';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accountSubject: BehaviorSubject<ExternalLoginFacebookModel>;
  userData = new BehaviorSubject<User>(new User());

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private notifyService: NotificationService, private _externalAuthService: SocialAuthService) { }

  public get accountValue(): ExternalLoginFacebookModel {
    return this.accountSubject.value;
  }

  login(userDetails) {
    return this.http.post<any>('/api/login', userDetails)
      .pipe(map(response => {
        localStorage.setItem('authToken', response.token);
        localStorage.setItem('userId', response.userDetails.id);
        this.setUserDetails();
        this.openDefaultPage();
        return response;
      }));
  } 

  signInWithGoogle = ()=> {
    return this._externalAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInFacebookLogin = () => {
    return this._externalAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  externalLogin(url: string, externalLogingModel: ExternalLoginModel) {
    return this.http.post<any>(url, externalLogingModel)
      .pipe(map(response => {
        localStorage.setItem('authToken', response.token);
        localStorage.setItem('userId', response.userDetails.id);
        this.setUserDetails();
        this.openDefaultPage();
        return response;
      }));
  }

  externalLoginFacebook(url: string, externalLogingModel: LoginFacebook) {
    return this.http.post<any>(url, externalLogingModel)
      .pipe(map(response => {
        localStorage.setItem('authToken', response.token);
        localStorage.setItem('userId', response.userDetails.id);
        this.setUserDetails();
        this.openDefaultPage();
        return response;
      }));
  }

  signOutExternal = () => {
    this._externalAuthService.signOut();
  }

  updateData(userId: string) {
    return this.http.post<any>('/api/login/updatedata', { userId: userId })
      .pipe(map(response => {
        localStorage.setItem('authToken', response.token);
        localStorage.setItem('userId', response.userDetails.id);
        this.setUserDetails();
        return response;
      }));
  }

  register(userDetails) {
    return this.http.post<any>('/api/user/register', userDetails)
      .pipe(map(response => {
        return response;
      }));
  }

  register1(userDetails) {
    return this.http.post<any>('/api/user/register1', userDetails)
      .pipe(map(response => {
        return response;
      }));
  }

  resendConfirmEmail(userDetails) {
    return this.http.post<any>('/api/user/resendConfirmEmail', userDetails)
      .pipe(map(response => {
        this.notifyService.showInfo("The resend email was successfully sent", null)
        return response;
      }), catchError((err, caught) => {
        this.notifyService.showWarning("The email was sent please try later", null)
        return err;
      }));
  }

  resetPassword(userDetails) {
    return this.http.post<any>('/api/user/resetPassword', userDetails)
      .pipe(map(response => {
        return response;
      }));
  }

  forgotPassword(userDetails) {
    return this.http.post<any>('/api/user/forgotPassword', userDetails)
      .pipe(map(response => {
        return response;
      }));
  }

  changePassword(userDetails) {
    return this.http.post<any>('/api/user/changePassword', userDetails)
      .pipe(map(response => {
        return response;
      }));
  }

  ConfirmAccount(userDetails) {
    return this.http.post<any>('/api/user/ConfirmAccount', userDetails)
      .pipe(map(response => {
        return response;
      }));
  }


  saveUserLogo(formData: any) {
    return this.http.post<any>('/api/user/save-avatar/', formData)
      .pipe(map(response => {
        return response;
      }));
  }


  deleteUserLogo() {
    return this.http.delete<any>('/api/user/delete-avatar', { reportProgress: true })
      .pipe(map(response => {
        return response;
      }));
  }

  imprsonateIn(userId: string) {
    return this.http.post<any>('/api/login/imprsonateIn', { userId: userId })
      .pipe(map(response => {
        localStorage.setItem('authToken', response.token);
        localStorage.setItem('userId', response.userDetails.id);
        this.setUserDetails();
        this.openDefaultPage();
        return response;
      }));
  }

  imprsonateOut(userId: string) {
    return this.http.post<any>('/api/login/imprsonateOut', { userId: userId })
      .pipe(map(response => {
        localStorage.setItem('authToken', response.token);
        localStorage.setItem('userId', response.userDetails.id);
        this.setUserDetails();
        this.openDefaultPage();
        return response;
      }));
  }

  setUserDetails() {
    if (localStorage.getItem('authToken')) {
      const userDetails = new User();
      const decodeUserDetails = JSON.parse(window.atob(localStorage.getItem('authToken').split('.')[1]));
      userDetails.userName = decodeUserDetails.sub;
      userDetails.firstName = decodeUserDetails.firstName;
      userDetails.logoPath = decodeUserDetails.logoPath;
      userDetails.avatarPath = decodeUserDetails.avatarPath;
      userDetails.isLoggedIn = true;
      userDetails.role = decodeUserDetails.role;
      userDetails.userId = decodeUserDetails.userId;
      userDetails.impersonatedUserId = decodeUserDetails.impersonatedUserId;
      userDetails.impersonatedUserName = decodeUserDetails.impersonatedUserName;
      userDetails.isImpersonate = decodeUserDetails.isImpersonate;
      userDetails.intercomContactId = decodeUserDetails.intercomContactId;
      userDetails.fbUserId = decodeUserDetails.fbUserId;
      this.userData.next(userDetails);
    }
  }

  changeavatar(avatarLink) {
    let userDetails = <User> { ... this.userData.value, avatarPath: avatarLink };
    this.userData.next(userDetails);
  }

  logout() {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('photoUrl');
    this.router.navigate(['/login']);
    this.userData.next(new User());
  }

  openDefaultPage() {
    if (this.userData.value.isLoggedIn) {
      if (this.userData.value.role === UserRole.User) {
        this.router.navigate(['/dashboard']);
      }else if (this.userData.value.role === UserRole.ManufactureUser) {
        this.router.navigate(['/manufacture-dashboard']);
      } else if (this.userData.value.role === UserRole.Admin) {
        this.router.navigate(['/admin-dashboard-approve']);
      }
    }
  }
}
