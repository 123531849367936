import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { DownloadProgressService } from '../../services/download-service';
import { ProjectService } from '../../services/project.service';
import { SignalRService } from '../../services/signal-r.service';
import { UserService } from '../../services/user.service';
import { AdminProjectService } from '../admin-services/admin-project.service';
import { ISelectItemDto } from '../models/ISelectItemDto';

declare var $: any;

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  countDown: Subscription;
  dto: any = [];
  loading: boolean = false;
  currentProject: any;
  userListImpersonate: ISelectItemDto[];
  filter: any = { name: "", sort: "date desc" };
  filterApplied: any = { name: "", sort: "date desc" };
  scrollProjects: Subscription;
  userData = new User();
  userDataSubscription: any;
  processingPaging: boolean = false;
  constructor(private authService: AuthService, private userService: UserService, private projectService: ProjectService,
    private adminProjectService: AdminProjectService, private router: Router, private signalr: SignalRService,
    private downloadService: DownloadProgressService  ) {
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
    });
  }

  tick = 1000;
  ngOnInit() {
 
    this.adminProjectService.getUsersForImpersonate().subscribe(data => {
      this.userListImpersonate = data;
      this.getProjects(true, 0, 30);
    });

    this.scrollProjects = this.signalr.addScrollEvent().subscribe((data: any) => {
      if (data && (data.target.scrollTop + data.target.offsetHeight) * 1.5 > data.target.scrollHeight)
        if (this.userData.userId) {
          this.getProjects(false, this.dto.length, 10);
        }

    });
  }

  ngOnDestroy() {
    if (this.scrollProjects != null) {
      this.scrollProjects.unsubscribe();
    }
  }

  filterProjects() {
    this.filterApplied.name = this.filter.name;
    this.filterApplied.sort = this.filter.sort;
    this.getProjects(true, 0, 30);
  }

  filterProjectsRefresh() {
    this.filterApplied.name = "";
    this.filterApplied.sort = "";
    this.filter.name = "";
    this.filter.sort = "date desc";
    this.getProjects(true, 0, 30);
  }

  getProjects(isShowLoading: boolean, skip, take) {

    if (isShowLoading) {
      this.loading = true;
    }
    else if (this.processingPaging) {
      return
    }
    this.processingPaging = true;
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.dto) {
        for (var i = 0; i < this.dto.length; i++) {
          this.dto[i].secondsLeft = --this.dto[i].secondsLeft;
        }
      }
    });

    this.adminProjectService.getProjects(window.location.pathname.includes("admin-dashboard-approve"), this.filterApplied.name, this.filterApplied.sort, skip, take)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.processingPaging = false;
         
          if (isShowLoading && skip == 0) {
            this.dto = result;
          }
          else {
            var index = skip;
            for (var item in result) {
              var project = result[item];
              var existProject = this.dto.filter(x => x.id == project.id);
              if (existProject.length > 0) {
                var existIndex = this.dto.indexOf(existProject[0]);
                this.dto[existIndex] = project
              }
              else {
                this.dto[index++] = result[item];
              }
            }
          }
          if (this.dto && this.dto.length > 0 && this.userListImpersonate && this.userListImpersonate.length > 0) {
            for (var i = 0; i < this.dto.length; i++) {
              const user = this.userListImpersonate.filter(u => u.id == this.dto[i].createdByUser);
              if (user) {
                if (user.length != 0) {
                  const id = user[0].text;
                  if (id) {
                    this.dto[i].email = this.userListImpersonate.filter(u => u.id == this.dto[i].createdByUser)[0].text
                  }
                }
              }
            }
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        });
  }

  goToEditPage(id) {
    if (id) {
      this.projectService.getProjectFileZipped(+id, true).subscribe(
        (data) => {
          this.downloadService.getStls(data.stlFiles);
          this.downloadService.getImages(data.imageFiles);
          this.router.navigate(['/edit-project/' + id]);
        });      
      localStorage.setItem('projectId', id);
    }
  }

  toFiles() {
    this.projectService.toFiles().subscribe((data: any) => {
    });
  }

  deleteProject() {
    if (this.currentProject && this.currentProject.id) {
      this.projectService.deleteProject(this.currentProject.id).subscribe((data: any) => {
        this.dto = this.dto.filter(x => x.id != this.currentProject.id);
      });
    }
  }

  showModal(project): void {
    this.currentProject = project;  
    $("#confirmModal").modal('show');
  }

  hideModal(): void {
    this.currentProject = null;
    $("#confirmModal").modal('hide');
  }
}
