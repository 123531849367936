import { UserRole } from "./roles";

export class User {
  userName: string;
  firstName: string;
  lastName: string;
  companyName: string;
  isLoggedIn: boolean;
  email: string;
  contactRole: string;
  role: string;
  logoPath: string;
  avatarPath: string;
  userId: string;
  phoneNumber: string;
  isImpersonate: string;
  impersonatedUserId: string;
  impersonatedUserName: string;
  intercomContactId: string;
  fbUserId: string;
  isFbUserId: boolean;

  public isAdmin(): boolean {
    return this.role == UserRole.Admin;
  }
}
